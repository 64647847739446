import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Text, Tooltip} from '@gravity-ui/uikit';
import {Envelope, Copy} from '@gravity-ui/icons';
import ManagerPng from '@assets/avatar-manager.png';
import Whatsapp from '@assets/whatsapp-icon.svg';

import './ContactCard.scss';

const b = block('contact-card');

interface ContactProps {
    children: React.ReactNode;
    copyText: string;
}

const Contact: React.FC<ContactProps> = ({children, copyText}) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <Tooltip
            content="Скопировано"
            placement="top-end"
            disabled={!openTooltip}
            openDelay={0}
            contentClassName={b('tooltip')}
            className={b('tooltip-container')}
        >
            <div
                className={b('copy')}
                onClick={() => {
                    setOpenTooltip(true);
                    navigator.clipboard.writeText(copyText);
                    setTimeout(() => setOpenTooltip(false), 1000);
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};

const ContactCard: React.FC = () => (
    <div className={b()}>
        <div className={b('contact-container')}>
            <img src={ManagerPng} alt="Менеджер" />

            <div className={b('contact-info')}>
                <Text variant="subheader-2">Ваш менеджер:</Text>

                <Text variant="body-2">Иванов Александр</Text>

                <Contact copyText="ivanov@zapnsk.ru">
                    <Envelope />
                    <Text variant="body-1">ivanov@zapnsk.ru</Text>
                </Contact>

                <div className={b('contact-number')}>
                    <Contact copyText="+7(913)999-99-99">
                        <Copy />
                        <Text variant="body-1">+7(913)999-99-99</Text>
                    </Contact>

                    <Tooltip
                        content={
                            <Text className={b('whatsapp-tooltip-content')}>
                                Откроется Whatsapp в браузере
                            </Text>
                        }
                        placement="top-end"
                        contentClassName={b('whatsapp-tooltip')}
                        className={b('whatsapp-tooltip-container')}
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <a
                            href="https://web.whatsapp.com/send?phone=79139999999"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={b('whatsapp-link')}
                        >
                            <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                    </Tooltip>
                </div>
            </div>
        </div>

        <div className={b('separator')}></div>

        <div className={b('contact-container')}>
            <Text variant="subheader-2">
                Специалист<br/>клиентской<br/>поддержки:
            </Text>

            <div className={b('contact-info')}>
                <Text variant="body-2">Петров Сергей</Text>

                <Contact copyText="petrov@zapnsk.ru">
                    <Envelope />
                    <Text variant="body-1">petrov@zapnsk.ru</Text>
                </Contact>

                <div className={b('contact-number')}>
                    <Contact copyText="+7(913)999-99-99">
                        <Copy />
                        <Text variant="body-1">+7(913)999-99-99</Text>
                    </Contact>
                    <Tooltip
                        content={
                            <Text className={b('whatsapp-tooltip-content')}>
                                Откроется Whatsapp в браузере
                            </Text>
                        }
                        placement="top-end"
                        contentClassName={b('whatsapp-tooltip')}
                        className={b('whatsapp-tooltip-container')}
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <a
                            href="https://web.whatsapp.com/send?phone=79139999999"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={b('whatsapp-link')}
                        >
                            <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                    </Tooltip>
                </div>
            </div>
        </div>

        <div className={b('contact-container')}>
            <Text variant="subheader-2">
                Руководитель <br /> отдела продаж:
            </Text>

            <div className={b('contact-info')}>
                <Text variant="body-2">Федоров Сергей</Text>

                <Contact copyText="fedorov@zapnsk.ru">
                    <Envelope />
                    <Text variant="body-1">fedorov@zapnsk.ru</Text>
                </Contact>

                <div className={b('contact-number')}>
                    <Contact copyText="+7(913)999-99-99">
                        <Copy />
                        <Text variant="body-1">+7(913)999-99-99</Text>
                    </Contact>
                    <Tooltip
                        content={
                            <Text className={b('whatsapp-tooltip-content')}>
                                Откроется Whatsapp в браузере
                            </Text>
                        }
                        placement="top-end"
                        contentClassName={b('whatsapp-tooltip')}
                        className={b('whatsapp-tooltip-container')}
                        openDelay={0}
                        closeDelay={0}
                        disabled={false}
                    >
                        <a
                            href="https://web.whatsapp.com/send?phone=79139999999"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={b('whatsapp-link')}
                        >
                            <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                    </Tooltip>
                </div>
            </div>
        </div>
    </div>
);

export default ContactCard;
