import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {DatePicker, RangeDatePicker, RangeValue} from '@gravity-ui/date-components';
import {Button, Text} from '@gravity-ui/uikit';
import {FiltersModel} from '@features/orders/interfaces';
import {DateTime, dateTimeParse} from '@gravity-ui/date-utils';

import './CustomRangeDatePicker.scss';

import block from 'bem-cn-lite';

const b = block('custom-range-date-picker');

interface CustomRangeDatePickerProps {
    filters: FiltersModel;
    setFilters: Dispatch<SetStateAction<FiltersModel>>;
    className?: string;
    rangeKey: 'createdAtRange' | 'deliveryDateRange';
}

export const CustomRangeDatePicker: React.FC<CustomRangeDatePickerProps> = ({
    filters,
    setFilters,
    className,
    rangeKey
}) => {
    const [startDate, setStartDate] = useState<DateTime | null>(filters[rangeKey]?.start || null);
    const [endDate, setEndDate] = useState<DateTime | null>(filters[rangeKey]?.end || null);
    const [isRangePickerOpen, setRangePickerOpen] = useState(false);
    const [isManualChange, setIsManualChange] = useState(false);

    const placeholder = rangeKey === 'createdAtRange' ? 'Дата заказа' : 'Дата доставки';

    useEffect(() => {
        if (!isManualChange) {
            setStartDate(filters[rangeKey]?.start || null);
            setEndDate(filters[rangeKey]?.end || null);
        }
        setIsManualChange(false);
    }, [filters, rangeKey]);

    function handleStartDateChange(value: DateTime | null) {
        setIsManualChange(true);
        setStartDate(value);
        updateRange(value, endDate);
    }

    function handleEndDateChange(value: DateTime | null) {
        setIsManualChange(true);
        setEndDate(value);
        updateRange(startDate, value);
    }

    function updateRange(start: DateTime | null, end: DateTime | null) {
        if (start && end) {
            const adjustedEnd = end.endOf('day');
            setFilters((prev) => ({
                ...prev,
                [rangeKey]: {start, end: adjustedEnd}
            }));
        } else {
            setFilters((prev) => ({
                ...prev,
                [rangeKey]: null
            }));
        }
    }

    function getRangeDatePickerValue(): RangeValue<DateTime> | null {
        if (startDate && endDate) {
            return {start: startDate, end: endDate};
        }
        return null;
    }

    function setToday() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfDay = today.startOf('day');
            const endOfDay = today.endOf('day');
            setStartDate(startOfDay);
            setEndDate(endOfDay);
            updateRange(startOfDay, endOfDay);
        }
    }

    function setCurrentWeek() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfWeek = today.startOf('week');
            const endOfWeek = today.endOf('week');
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);
            updateRange(startOfWeek, endOfWeek);
        }
    }

    function setCurrentMonth() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfMonth = today.startOf('month');
            const endOfMonth = today.endOf('month');
            setStartDate(startOfMonth);
            setEndDate(endOfMonth);
            updateRange(startOfMonth, endOfMonth);
        }
    }

    function setLastMonth() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfLastMonth = today.subtract(1, 'month').startOf('month');
            const endOfLastMonth = today.subtract(1, 'month').endOf('month');
            setStartDate(startOfLastMonth);
            setEndDate(endOfLastMonth);
            updateRange(startOfLastMonth, endOfLastMonth);
        }
    }

    function setNextMonth() {
        const today = dateTimeParse(new Date());
        if (today) {
            const startOfNextMonth = today.add(1, 'month').startOf('month');
            const endOfNextMonth = today.add(1, 'month').endOf('month');
            setStartDate(startOfNextMonth);
            setEndDate(endOfNextMonth);
            updateRange(startOfNextMonth, endOfNextMonth);
        }
    }

    return (
        <RangeDatePicker
            size="m"
            hasClear
            className={className}
            placeholder={placeholder}
            value={getRangeDatePickerValue()}
            onUpdate={(value) => {
                if (value) {
                    setStartDate(value.start);
                    setEndDate(value.end);
                    updateRange(value.start, value.end);
                } else {
                    setStartDate(null);
                    setEndDate(null);
                    updateRange(null, null);
                }
            }}
            format="DD.MM.YYYY"
            open={isRangePickerOpen}
            onOpenChange={(open) => setRangePickerOpen(open)}
        >
            {() => (
                <div className={b()}>
                    <div className={b('date-picker-container')}>
                        <DatePicker
                            size="m"
                            hasClear
                            placeholder="ДД.MM.ГГГГ"
                            label="C:"
                            value={startDate}
                            onUpdate={handleStartDateChange}
                            format="DD.MM.YYYY"
                        />

                        <DatePicker
                            size="m"
                            hasClear
                            placeholder="ДД.MM.ГГГГ"
                            label="До:"
                            value={endDate}
                            onUpdate={handleEndDateChange}
                            format="DD.MM.YYYY"
                        />

                        <Button
                            className={b('button')}
                            size="m"
                            onClick={() => setRangePickerOpen(false)}
                        >
                            Применить
                        </Button>
                    </div>

                    <div className={b('presets-container')}>
                        <Text variant="body-1" className={b('preset-item')} onClick={setToday}>
                            Сегодня
                        </Text>
                        <Text
                            variant="body-1"
                            className={b('preset-item')}
                            onClick={setCurrentWeek}
                        >
                            Текущая неделя
                        </Text>
                        <Text
                            variant="body-1"
                            className={b('preset-item')}
                            onClick={setCurrentMonth}
                        >
                            Текущий месяц
                        </Text>
                        <Text variant="body-1" className={b('preset-item')} onClick={setLastMonth}>
                            Прошлый месяц
                        </Text>
                        <Text variant="body-1" className={b('preset-item')} onClick={setNextMonth}>
                            Следующий месяц
                        </Text>
                    </div>
                </div>
            )}
        </RangeDatePicker>
    );
};
