import {OrderItemModel} from '../interfaces';

export const defaultSortPriority = ['price', 'delivery-date', 'quantity'];

export const defaultDirection = new Map<string, number>([
    ['price', 1],
    ['delivery-date', -1],
    ['quantity', -1],
    ['ref', 1],
    ['sum', 1],
    ['date', -1]
]);

export function sortItems(
    items: OrderItemModel[] | undefined,
    currentSortPriority: string[],
    sortOption: {property: string; direction: 'asc' | 'desc'} | null,
    defaultDirection: Map<string, number>
) {
    if (!items) return [];

    return items.slice().sort((a, b) => {
        for (const property of currentSortPriority) {
            let aValue = getSortValue(a, property);
            let bValue = getSortValue(b, property);

            let comparison = compareValues(aValue, bValue);

            if (comparison !== 0) {
                let direction: number;
                if (sortOption && sortOption.property === property) {
                    direction = sortOption.direction === 'asc' ? 1 : -1;
                } else {
                    direction = defaultDirection.get(property) || 1;
                }
                return comparison * direction;
            }
        }
        return 0;
    });
}

function getSortValue(item: OrderItemModel, sortField: string) {
    switch (sortField) {
        case 'price':
            return item.price;
        case 'delivery-date':
            return item.deliveryDate;
        case 'quantity':
            return item.quantity;
        case 'ref':
            return item.refId;
        case 'sum':
            return item.sum;
        case 'date':
            return item.createdAt;
        default:
            return 0;
    }
}

function compareValues(a: any, b: any) {
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
    }
    if (a instanceof Date && b instanceof Date) {
        return a.getTime() - b.getTime();
    }
    return 0;
}
