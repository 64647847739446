import {useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from 'app/context';
import {OrderSearch} from '@services/user-gw';
import {CApi as userGwApi} from '@services/api';
import {FiltersModel} from '@features/orders/interfaces/FiltersModel';
import {OrderItemModel} from '@features/orders/interfaces';

export const useOrders = () => {
    const {state, emit} = useContext(AppContext);
    const {items} = state.orders;
    const itemsRef = useRef<OrderItemModel[]>(items);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredItems, setFilteredItems] = useState<OrderItemModel[]>(items);
    const [filters, setFilters] = useState<FiltersModel>({
        createdAtRange: null,
        deliveryDateRange: null,
        statuses: [
            {value: 'codeВыдан', label: 'Выдан', color: 'positive-heavy'},
            {value: 'codeОтказ', label: 'Отказ', color: 'danger-heavy'}
        ],
        brands: [
            {value: 'Patron', label: 'Patron'},
            {value: 'Ford', label: 'Ford'}
        ],
        refs: [
            {value: '0001', label: '0001'},
            {value: '0002', label: '0002'}
        ],
        articles: [
            {value: '1508109', label: '1508109'},
            {value: 'PBK4803', label: 'PBK4803'}
        ],
        price: {},
        sum: {},
        onlyWithComments: false
    } as FiltersModel);

    const hasLoaded = useRef(false);

    useEffect(() => {
        if (hasLoaded.current) return;
        hasLoaded.current = true;

        const searchData: OrderSearch = {
            limit: 5000,
            offset: 0
        };

        // TODO Выяснить, как правильно использовать методы API для получения значений для фильтров
        // userGwApi()
        //     .orderSearchFilterValue.orderSearchFilterValueDetail('status', {q:''})
        //     .then((response) => {
        //     });
        //
        // userGwApi()
        //     .orderSearchFilterValue.orderSearchFilterValueDetail('ref_id', {q:''})
        //     .then((response) => {
        //     })
        //     .catch((err) => console.error(err));
        //
        // userGwApi()
        //     .orderSearchFilterValue.orderSearchFilterValueDetail('brand_name', {q:''})
        //     .then((response) => {
        //     })
        //     .catch((err) => console.error(err));

        userGwApi()
            .orderSearch.orderSearchCreate(searchData)
            .then((response) => {
                const updatedItems = response.data.items.map((x) => {
                    return {
                        key: `${x.refId}:${x.article}`, // TODO refId должен быть уникальным, но это не так. Договориться с бэком о ключе
                        article: x.article,
                        comment: x.comment,
                        articleName: x.articleName,
                        brandName: x.brandName,
                        createdAt: new Date(Date.parse(x.createdAt)),
                        flags: x.flags,
                        return: x.return,
                        deliveryDate: new Date(Date.parse(x.deliveryInfo?.expected.client.date)),
                        price: x.price,
                        quantity: x.quantity,
                        refId: x.refId,
                        status: x.status,
                        sum: x.sum,
                        statuses: x.statuses
                    } as OrderItemModel;
                });

                itemsRef.current = updatedItems;

                const brands = [...new Set(updatedItems.map((x) => x.brandName))].sort();
                const articles = [...new Set(updatedItems.map((x) => x.article))].sort();
                const refs = [...new Set(updatedItems.map((x) => x.refId))].sort();
                const statusesMap = updatedItems.reduce(
                    (acc, cur) => acc.set(cur.status.id, cur.status.name),
                    new Map<string, string>()
                );

                setFilters((prev) => {
                    return {
                        ...prev,
                        brands: brands.map((xx) => {
                            return {
                                value: xx,
                                label: xx,
                                isSelected: false
                            };
                        }),
                        articles: articles.map((xx) => {
                            return {
                                value: xx,
                                label: xx,
                                isSelected: false
                            };
                        }),
                        refs: refs.map((xx) => {
                            return {
                                value: xx,
                                label: xx,
                                isSelected: false
                            };
                        }),
                        statuses: [...statusesMap].map(([key, value]) => {
                            return {
                                value: key,
                                label: value,
                                isSelected: false
                            };
                        })
                    };
                });

                emit('updateOrders', {
                    orders: {
                        items: updatedItems
                    }
                });
            })
            .catch((error) => {
                console.error('Ошибка при поиске заказов:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (isLoading) return;

        const f = filters;
        const newFilteredItems = itemsRef.current.filter((x) => {
            if (f.createdAtRange) {
                const fromCreatedAt = f.createdAtRange.start.toDate();
                const toCreatedAt = f.createdAtRange.end.toDate();
                const isInRange = x.createdAt <= toCreatedAt && x.createdAt >= fromCreatedAt;
                if (!isInRange) return false;
            }

            if (f.deliveryDateRange) {
                const fromCreatedAt = f.deliveryDateRange.start.toDate();
                const toCreatedAt = f.deliveryDateRange.end.toDate();
                const isInRange = x.deliveryDate <= toCreatedAt && x.deliveryDate >= fromCreatedAt;
                if (!isInRange) return false;
            }

            const selectedStatuses = f.statuses.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedStatuses.length > 0) {
                const isInState = selectedStatuses.includes(x.status.id);
                if (!isInState) return false;
            }

            const selectedRefs = f.refs.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedRefs.length > 0) {
                const isInState = selectedRefs.includes(x.refId);
                if (!isInState) return false;
            }

            const selectedBrands = f.brands.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedBrands.length > 0) {
                const isInState = selectedBrands.includes(x.brandName);
                if (!isInState) return false;
            }

            const selectedArticles = f.articles.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedArticles.length > 0) {
                const isInState = selectedArticles.includes(x.article);
                if (!isInState) return false;
            }

            if (f.price.from) {
                const isLesser = x.price < f.price.from;
                if (isLesser) return false;
            }

            if (f.price.to) {
                const isGreater = x.price > f.price.to;
                if (isGreater) return false;
            }

            if (f.sum.from) {
                const isLesser = x.sum < f.sum.from;
                if (isLesser) return false;
            }

            if (f.sum.to) {
                const isGreater = x.sum > f.sum.to;
                if (isGreater) return false;
            }

            if (f.onlyWithComments) {
                const hasComments = !!x.comment;
                if (!hasComments) return false;
            }

            return true;
        });

        setFilteredItems(newFilteredItems);
        setIsFiltered(true);

        emit('updateOrders', {
            orders: {
                items: newFilteredItems
            }
        });
    }, [filters, isLoading]);

    return {
        originalItems: itemsRef.current,
        items: filteredItems,
        filters,
        setFilters,
        isLoading,
        isFiltered
    };
};
